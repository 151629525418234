import { Card, CardContent, Typography } from '@mui/material';
import { Question } from '../types';

export type QuestionItemProps = {
  question: Question;
};

const QuestionItem = ({ question }: QuestionItemProps) => {
  return (
    <Card className='questionMapper'>
        <CardContent>
          <Typography>{question.title}</Typography> <br />
        </CardContent>
    </Card>
  );
};

export default QuestionItem;
