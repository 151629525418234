import React from 'react';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import Loading from '../einhorn/loading';

const ProtectedRoute = ({ component, ...propsForComponent} : any) => {

    const Component = withAuthenticationRequired(component, {
        onRedirecting: () => (
          <div className="page-layout">
            <Loading />
          </div>
        ),
      });
    
      return <Component />;
  }

export default ProtectedRoute;