import { Container, Grid, Paper, Typography } from "@mui/material";

export default function DepartmentTile({ report }: any) {
  console.log(report);
  if(report == null){
    return(<></>)
  }
  return (
    <Paper>
      {" "}
      <Container>
        <br />
        <Typography variant="h6">
          Department
        </Typography>
        <br />
          {Object.keys(report).map(dept => {
            return (
              <Grid container spacing={1} key={dept}>
                <Grid item xs={6}>
                  <span className="secondaryContent">{dept} </span>
                </Grid>
                <Grid item xs={6}>
                {report[dept]}
                </Grid>
              </Grid >
            )
          })}
        <br />
      </Container>
    </Paper>
  )

}