import DynamicInput from "@einhorn/dynamicInput";
import { Button, Grid, TextField, Typography } from "@mui/material";
export default function ProgramOutcomes({ value, setValue }: any) {

  return (
    <div>
      <Typography variant="subtitle1" gutterBottom>
      Paste in the learning outcomes for your program, so we can understand your goals and generate the most useful report for you.
      </Typography>
      <br />
      <DynamicInput value={value} setValue={setValue} property="outcomes" label="Outcome"/>
    </div>
  );
}
