import { createTheme } from '@mui/material/styles';
import { red } from '@mui/material/colors';

// A custom theme for this app
const theme = createTheme({
  palette: {
    primary: {
      main: '#1E1E1E',
    },
    secondary: {
      main: '#19857b',
    },
    error: {
      main: red.A400,
    }
  },
  typography: {
    "fontFamily": `Jakarta', 'Arial', 'sans-serif`,
    "fontSize": 14,
    "fontWeightLight": 300,
    "fontWeightRegular": 400,
    "fontWeightMedium": 500
   },
   components: {
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundColor: 'white',
          border: '1px solid #E5E4E7',
          boxShadow: 'none',
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'capitalize',
          fontSize: '13px',
          fontWeight: '600',
          letterSpacing: '0.5px',
        }
      }
    }
   }
});

export default theme;