import {
    Button,
    ButtonGroup,
    Container,
    Grid,
    LinearProgress,
    Paper,
    Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import FathomClient from "../../../api/fathomapi";
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate, useParams } from "react-router-dom";
import { ReportDto } from "./report";
import ViewExecReport from "./reportexec";
import ViewFullReport from "./reportfull";
import ViewQuestionsReport from "./reportQuestions";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
const env = process.env.REACT_APP_ENV;

export default function ViewReport() {
    const { getAccessTokenSilently } = useAuth0();
    const navigate = useNavigate();
    const [report, setReport] = useState({} as ReportDto);
    const [loading, setLoading] = useState(true);
    const { programId, reportId, reportType } = useParams();

    
    useEffect(() => {
        // declare the data fetching function
        const fetchData = async () => {
            const apiClient = new FathomClient(await getAccessTokenSilently());
            const { data } = await apiClient.get(`{clientId}/programs/` + programId + `/reports/` + reportId);
            setReport(data);
            setLoading(false);

            if(data.status === "Complete") {
                clearInterval(interval)
            }
        };

        setLoading(true);
        // call the function
        fetchData()
            // make sure to catch any error
            .catch(console.error);

        const interval = setInterval(() => {
            fetchData()
                // make sure to catch any error
                .catch(console.error);
        }, 30000);
    }, [getAccessTokenSilently]);


    const replay = async () => {
        const apiClient = new FathomClient(await getAccessTokenSilently());
        const { data } = await apiClient.put(`{clientId}/programs/` + programId + `/reports/` + reportId + '/replay', {});
    }

    const viewExec = () => {
        navigate("/programs/" + programId + "/reports/" + reportId + "/view/exec");
    }
    const viewFull = () => {

        navigate("/programs/" + programId + "/reports/" + reportId + "/view/full");
    }
    const viewQuestions = () => {

        navigate("/programs/" + programId + "/reports/" + reportId + "/view");
    }

    const goBack = () => {
        navigate("/programs/" + programId + "/reports/");
    }

    const preProgram = () => {
        return report.evaluationPoint.toLowerCase() == "preprogram";
    }

    const syncing = () => {
        return report.status !== "Complete";
    }

    const isError = (status:string) => {
        return (status || "").toLowerCase().indexOf("error") > -1;
    }

    const statusMap=(status:string) => {
        if(report.status == "QuestionAnalysis") {
            return "Analysing questions"
        }

        if(report.status == "OutcomeAnalysis") {
            return "Analysing outcome"
        }

        if(report.status == "ReportGen") {
            return "Generating final report"
        }

        if(report.status == "BatchError") {
            return "Bacthing Error"
        }

        if(report.status == "IndexError") {
            return "Indexing error"
        } 
        
        if(report.status == "QuestionError") {
            return "Question Analysis Error"
        }

        if(report.status == "OutcomeError") {
            return "Outcome Analysis Error"
        }

        if(report.status == "ModuleError") {
            return "Module Analysis Error"
        }

        if(report.status == "ReportError") {
            return "Report Generation Error"
        }
        


        return status;
    }
    return (
        <div>

            <Container>
                <br />
                <Button onClick={() => goBack()} sx={{opacity: syncing() ? "0" : "0.6", textTransform: 'none'}}><ArrowBackIcon sx={{width:"16px", height:"16px"}} /> &nbsp; Back to reports</Button>
                {env == "Dev" && <Button onClick={() => replay()} sx={{opacity: syncing() ? "0" : "0.6", textTransform: 'none'}}>Replay</Button> }
                <Grid container spacing={4}>
                    {syncing() &&
                        <Grid item xs={12} sx={{
                            marginTop: "calc(5% + 60px)",
                            bottom: '50%',
                            width: "100%",
                        }}>
                            <Button onClick={() => goBack()} sx={{opacity: "0.6", textTransform: 'none'}}><ArrowBackIcon sx={{width:"16px", height:"16px"}} /> &nbsp; Back to reports</Button>
                            <Paper  >
                                <Container>
                                    {isError(report.status) && 
                                    <>
                                        <br />
                                        <h4>We&apos;ve ran into an issue</h4>
                                        <Typography variant="subtitle2">Issue: {statusMap(report.status)}</Typography>
                                        <br />
                                        <br />
                                        <Typography variant="subtitle2">You can go back, click edit and upload your data again. <br/><br/> Quote report id : <b>{report.id}</b> to the team if you need more help.</Typography>
                                        <br />
                                        <br />

                                    </>
                                    }
                                    {!isError(report.status) && 
                                    <>
                                        <br />
                                        <br />
                                        <h4>Analysis in progress</h4>
                                        <LinearProgress color="secondary" />
                                        <br />
                                        <Typography variant="subtitle2">Current Status: {statusMap(report.status)}</Typography>
                                        <br />
                                        <Typography variant="subtitle2">This page will reload when analysis is complete</Typography>
                                        <br />
                                    </>
                                    }

                                </Container>
                            </Paper>
                        </Grid>
                    }
                    {!syncing() && <>
                        <br />
                        {loading &&
                            <Paper sx={{ p: 2, display: "flex", flexDirection: "column", marginBottom: "20px" }} >
                                <LinearProgress color="secondary" />
                                <br />
                                <br />
                                <br />
                            </Paper>
                        }
                        <Grid item xs={6}>
                        <Button onClick={() => goBack()}><h4 className="programOverview" >{report.programName} - {report.title}</h4></Button>
                            <Typography variant="subtitle2">&nbsp;&nbsp;{report.programDescription}</Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <ButtonGroup variant="contained" disableElevation aria-label="Basic button group">
                                <Button onClick={viewQuestions} variant={reportType == null? "contained" :"outlined"}>Question Summary</Button> 
                                <Button onClick={viewExec} variant={reportType == "exec"? "contained" :"outlined"} disabled={preProgram()}>Executive Summary</Button> 
                                <Button onClick={viewFull} variant={reportType == "full"? "contained" :"outlined"} disabled={preProgram()}>Full report</Button>
                            </ButtonGroup>
                        </Grid>
                    </>}
                </Grid>
            </Container>
            {!syncing() && !loading && reportType == "exec" &&
                <Container>
                    <ViewExecReport report={report} />
                </Container>
            }
            {!syncing() && !loading && reportType == "full" &&
                <ViewFullReport report={report} />
            }
            {!syncing() && !loading && reportType == null &&
                <ViewQuestionsReport report={report} />
            }

            <br />
        </div>
    );
}
