import React, { Component } from "react";
import "./custom.css";
import axios from "axios";
import Dashboard from "../components/dashboard/dashboard";

axios.defaults.baseURL = process.env.REACT_APP_API_ENDPOINT;
const api = axios.create({
  baseURL: process.env.REACT_APP_API_ENDPOINT,
});

export default class App extends Component<{}, any> {
  static displayName = App.name;
  constructor(props: any) {
    super(props);
    if(window.location.pathname.indexOf("invite") > -1){
      localStorage.setItem("saved_invite", window.location.pathname + window.location.search);
    }
  }

  render() {
    return (
      <Dashboard></Dashboard>
    );
  }
}