import { Container, Grid, Paper, Typography } from "@mui/material";

export default function RegionTile({report} : any) {
    if(report == null){
      return(<></>)
    }
    return(
        <Paper>
                {" "}
                <Container>
                  <br />
                  <Typography variant="h6">
                    Location
                  </Typography>
                  <br />
                {Object.keys(report).map(dept => {
                  if(dept == ""){ return <></>}
                  return (

                    <Grid container spacing={1} key={dept}>
                    <Grid item xs={6}>
                      <span className="secondaryContent">{dept} </span>
                    </Grid>
                    <Grid item xs={6}>
                    {report[dept]}
                    </Grid>
                  </Grid >


                  )
                })}
                <br />
                </Container>
              </Paper>
    )

}