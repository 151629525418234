import React from 'react';
import Box from '@mui/material/Box';
import { useDroppable } from '@dnd-kit/core';
import {
  SortableContext,
  horizontalListSortingStrategy ,
} from '@dnd-kit/sortable';
import Typography from '@mui/material/Typography';
import QuestionItem from './questionItem';
import SortableQuestionItem from './sortableQuestionItem';
import { Question, Structure } from '../types';
import EasyEdit, {Types} from 'react-easy-edit';

import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';
import { Grid } from '@mui/material';

type BoardSectionProps = {
  id: string;
  title: string;
  questions: Question[];
  setFormState: any,
  formState: any
};

const QuestionHeader = ({ id, title, questions, setFormState, formState }: BoardSectionProps) => {
  const { setNodeRef } = useDroppable({
    id,
  });

  const save = (value:string) => {
    let newMap = formState.posMap as Map<string, string>;
    newMap.forEach((currentHeader: string, key: string) => {
      if(currentHeader == title){
        newMap.set(key, value);
      }
    });

    let struct = formState.surveyStructure?.structure as Structure[];
    for (let index = 0; index < struct.length; index++) {
      const element = struct[index];
      if(element.questionHeader == title){
        element.questionHeader = value;
      }
      struct[index] = element;
    }

    formState.surveyStructure.structure = struct;

    setFormState({
      unmappedQuestions: formState.unmappedQuestions,
      posMap: newMap,
      surveyStructure: formState.surveyStructure
    });
  }

  return (
      <Box sx={{ padding: 2 }}>
        <Typography variant="h6" sx={{ mb: 2 }}>
          {(title == "Unmapped" || title == "New question") ? title :
            <EasyEdit
              type={Types.TEXT}
              onSave={save}
              saveButtonLabel={<DoneIcon/>}
              cancelButtonLabel={<CloseIcon/>}
              value={title}     
              />
          }
        </Typography>
        <hr />
        <SortableContext
          id={id}
          items={questions}
          strategy={horizontalListSortingStrategy }
        >
          <div ref={setNodeRef} style={{minHeight: '70px', minWidth: '100px'}}>
            <Grid container  >
              {questions.map((question) => (
                <Grid item xs={4} key={question.id} style={{ minWidth: '230px'}}>
                  <Box sx={{ mb: 2, mr: 2, opacity: question.id == "unmapped" ? 0 : 1  }}>
                    <SortableQuestionItem id={question.id}>
                      <QuestionItem question={question} />
                    </SortableQuestionItem>
                  </Box>
                </Grid>
              ))}
              </Grid>
            </div>

        </SortableContext>
      </Box>
    
  );
};

export default QuestionHeader;
