import Title from "@einhorn/title";
import {
  Grid,
  Paper,
  Container,
  Button,
  Toolbar,
  Box,
  LinearProgress,
} from "@mui/material";
import ProgramStepper from "./stepper";
import React, { useEffect, useState } from "react";
import ProgramSetup from "./steps/setup";
import ProgramOutcomes from "./steps/outcomes";
import FathomClient from "api/fathomapi";
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate, useParams } from "react-router-dom";
import dayjs from "dayjs";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';


export default function CreateProgram() {
  const [activeStep, setActiveStep] = useState(0);
  const [loading, setLoading] = useState(false);
  const { getAccessTokenSilently } = useAuth0();
  const { id, step } = useParams();
  const navigate = useNavigate();

  const [programData, setProgramData] = useState([]);

  const [program, setProgram] = useState({
    name: "",
    description: "",
    deliveryMode: "online",
    evaluationPoint: "preprogram",
    outcomes: ["", "", "", ""],
    evaluationQuestions: ["", "", "", ""],
    programData: [],
    state: "Draft",
    id: "",
    dataSource: "Mentimeter",
    startDate: dayjs(),
    durationDays: 30,
    reportIds: [],
    headers: {}
  });
  useEffect(() => {
    if (id !== "" && id !== undefined) {
      setLoading(true);
      const fetchData = async () => {
        const apiClient = new FathomClient(await getAccessTokenSilently());
        const { data } = await apiClient.get(`{clientId}/programs/` + id);
        if (data.outcomes == null) {
          data.outcomes = [];
        }
        if (data.evaluationQuestions == null) {
          data.evaluationQuestions = [];
        }
        if (data.postAssessmentQuestions == null) {
          data.postAssessmentQuestions = [];
        }
        setProgram(data);
        setLoading(false);
      };

      fetchData().catch(console.error);
    }

    if (step !== "") {
      setActiveStep(parseInt(step || "0"))
    }

  }, [id, getAccessTokenSilently, step]);

  const handleNext = () => {
    saveDraft();
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => Math.max(prevActiveStep - 1, 0));
  };

  const handleSet = (i: number) => {
    setActiveStep(i);
  }

  const saveProgramData = async (parsedData: any) => {
    const apiClient = new FathomClient(await getAccessTokenSilently());
    const { data } = await apiClient.post(
      `{clientId}/programs/` + program.id + '/data',
      {
        outcomes: program.outcomes,
        structure: parsedData.structure
      }
    );
    setProgramData(data.Data);
  }

  const saveProgram = async () => {
    const apiClient = new FathomClient(await getAccessTokenSilently());
    setProgram({ ...program });
    const { data } = await apiClient.put(
      `{clientId}/programs/` + program.id,
      program
    );
    setProgram(data);

    navigate("/programs/" + program.id + "/reports")
  }

  const deleteProgram = async () => {
    if (confirm("This will delete the program, are you sure?")) {
      const apiClient = new FathomClient(await getAccessTokenSilently());
      if (program.id !== "") {
        await apiClient.delete(
          `{clientId}/programs/` + program.id
        );

        navigate("/programs/")
      }
    }
  };

  const saveDraft = async () => {
    const apiClient = new FathomClient(await getAccessTokenSilently());
    if (program.id == "") {
      const { data } = await apiClient.post(`{clientId}/programs`, program);
      setProgram(data);
    } else {
      const { data } = await apiClient.put(
        `{clientId}/programs/` + program.id,
        program
      );
      setProgram(data);
    }
  };

  const steps = [
    {
      label: "Program setup",
      content: <ProgramSetup value={program} setValue={setProgram} />
    },
    {
      label: "Define learning outcomes",
      content: <ProgramOutcomes value={program} setValue={setProgram} />
    },
    //   {
    //      label: "Evaluation questions",
    //     content: <ProgramBaseline value={program} setValue={setProgram} />
    //  },
    // {
    //   label: "Create end of program assessment",
    //   content: <ProgramPostAssess value={program} setValue={setProgram} />
    //  },
    //  {
    //    label: "Upload program data",
    //    content: <ProgramParticipants value={program} setValue={setProgram} setProgramData={setProgramData} saveProgramData={saveProgramData} />
    //  },
    //  {
    //    label: "Review and save",
    //   content: <SaveProgram value={program} setValue={setProgram} saveProgram={saveProgram} />
    //  },
  ];

  return (
    <div>
      
      <Container>  
      <Container>
      <br />

      <Button onClick={() => navigate("/programs")} sx={{ opacity: "0.6", textTransform: 'none', ml:"-10px" }}><ArrowBackIcon sx={{ width: "16px", height: "16px" }} /> &nbsp; Back to programs</Button>
        {(id != null && id !==undefined) &&
        <Button onClick={deleteProgram} variant="outlined" color="error" sx={{float: 'right', marginBottom: '-30px'}}>
          Delete
        </Button> }
        <br /> <br />
        <Grid container spacing={4}>
          <Grid item xs={8}>
            {" "}
            {!loading && (
              <Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>
                <br />
                <Container>
                  <Title>{steps[activeStep].label}</Title>
                  <hr />
                  {steps[activeStep].content}
                </Container>
              </Paper>
            )}
            {loading && (
              <Paper
                sx={{
                  p: 2,
                  display: "flex",
                  flexDirection: "column",
                  marginBottom: "20px",
                }}
              >
                <LinearProgress color="secondary" />
                <br />
                <br />
                <br />
              </Paper>
            )}
          </Grid>
          <Grid item xs={4}>

            <Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>
              <br />
              <Container>
                <Grid container spacing={2}>
                  <ProgramStepper
                    activeStep={activeStep}
                    handleNext={handleNext}
                    handleBack={handleBack}
                    handleClick={handleSet}
                    steps={steps}
                    saveProgram={saveProgram}
                    deleteProgram={deleteProgram}
                  />
                </Grid>
              </Container>
            </Paper>
          </Grid>
        </Grid>
        <br />

      </Container>
      </Container>
    </div>
  );
}
