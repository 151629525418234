import React  from 'react';
import { Auth0Provider} from '@auth0/auth0-react';
import { useNavigate } from 'react-router-dom';
import axios from "axios";

export const api = axios.create({
  baseURL: process.env.REACT_APP_API_ENDPOINT,
});

const Auth0ProviderWithHistory = ({ children } : any) => {
  const domain = process.env.REACT_APP_AUTH0_DOMAIN as string;
  const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID as string;
  const onRedirectCallbackUrl = process.env.REACT_APP_AUTH0_CALLBACK_URL as string;
  const navigate = useNavigate();
  const onRedirectCallback = (appState:any) => {
    navigate(appState?.returnTo || window.location.pathname);
  };

  return (
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      authorizationParams={{
        redirect_uri: onRedirectCallbackUrl,
        audience: 'https://api.getfathom.io',
        scope: "openid profile email offline_access"
      }}
      onRedirectCallback={onRedirectCallback}
      useRefreshTokens={true}
      cacheLocation="localstorage"
      useRefreshTokensFallback={true}
    >
      {children}
    </Auth0Provider>
  );
};

export default Auth0ProviderWithHistory;

