import { Typography } from "@mui/material";
import "./dataupload.css";
import SpreadSheetParse from "./spreadsheetparse";

export default function ProgramParticipants({ saveReportData }: any) {


  const onSubmit: (data: any) => void = (data: any) => {
    saveReportData(data);
  };

  return (
    <div>
      <Typography variant="subtitle1" gutterBottom>
        Export the data from your evaluation as a CSV file and drop it below. <br />
        Don’t worry about formatting, we’ll do all the hard work for you.
      </Typography>
      <br />
        <SpreadSheetParse onSubmit={onSubmit} />
    
    </div>
  );
}