import {
  Stepper,
  Step,
  StepLabel,
  StepContent,
  Box,
  Button,
} from "@mui/material";
import React from "react";



export default function ProgramStepper({activeStep, handleNext, handleBack, handleClick, steps, saveProgram, showDelete, deleteProgram}:any) {

  return (
    <Stepper activeStep={activeStep} orientation="vertical">
      {steps.map((step:any, idx:any) => (
        <Step key={step.label} onClick={() => handleClick(idx)}>
          <StepLabel>{step.label}</StepLabel>
          <StepContent>
            <Box sx={{ mb: 2 }}></Box>
          </StepContent>
        </Step>
      ))}
      <div>
{activeStep < 1 && 
        <Button variant="contained" onClick={handleNext} sx={{ mt: 1, mr: 1 }}>
          Continue
        </Button> }

        {activeStep == 1 && 
        <Button variant="contained" onClick={saveProgram} sx={{ mt: 1, mr: 1 }}>
          Save
        </Button> }
        <Button onClick={handleBack} sx={{ mt: 1, mr: 1 }}>
          Back
        </Button>
      </div>
    </Stepper>
  );
}
