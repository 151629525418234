import { useAuth0 } from "@auth0/auth0-react";
import Title from "@einhorn/title";
import { Container, Toolbar, Grid, Button, Paper, LinearProgress, Typography, Box, Modal, TextField, Select, MenuItem, SelectChangeEvent, InputLabel, Tab } from "@mui/material";
import FathomClient from "api/fathomapi";
import { useEffect, useState } from "react";
import CloseIcon from '@mui/icons-material/Close';
import ConfirmDialog from "@einhorn/confirmDialog";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import Moment from "moment";

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '56%',
    transform: 'translate(-50%, -50%)',
    width: 800,
    height: 550,
    bgcolor: 'white',
    boxShadow: 24,
    p: 4,
    "& > .MuiBackdrop-root": {
        bgcolor: 'white'
    }
};


export default function UsersList() {
    const { getAccessTokenSilently } = useAuth0();
    const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
    const [deleteUser, setDeleteUser] = useState({
        id: "",
        name: "",
        email: ""
    });
    const [users, setUsers] = useState([]);
    const [invites, setInvites] = useState([]);
    const [loading, setLoading] = useState(true);
    const [saveUserClicked, setSaveUserClicked] = useState(false);
    const [loadingInvites, setLoadingInvites] = useState(true);
    const [modalOpen, setModalOpen] = useState(false);
    const [newUser, setNewUser] = useState({
        name: "",
        email: "",
        UserFathomClientRole: "viewer"
    })

    const [tabValue, setTabValue] = useState('0');

    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        setTabValue(newValue);
    };

    const fetchUsersData = async () => {
        setLoading(true);
        const apiClient = new FathomClient(await getAccessTokenSilently());
        const { data } = await apiClient.get(`{clientId}/users`);
        setUsers(data);
        setLoading(false);
    };
    const fetchInvitesData = async () => {
        setLoadingInvites(true)
        const apiClient = new FathomClient(await getAccessTokenSilently());
        const { data } = await apiClient.get(`{clientId}/users/invites`);
        setInvites(data);
        setLoadingInvites(false)

    };

    useEffect(() => {
        fetchUsersData()
            .catch(console.error);
        fetchInvitesData()
            .catch(console.error);
    }, [getAccessTokenSilently]);

    const inviteUser = () => {
        setModalOpen(true);
    };

    const saveUser = async () => {
        setSaveUserClicked(true);
        const apiClient = new FathomClient(await getAccessTokenSilently());
        await apiClient.post(
            `{clientId}/users/add`,
            newUser
        );
        fetchInvitesData();
        setModalOpen(false);
        setSaveUserClicked(false);
    };

    const removeUser = async () => {
        const apiClient = new FathomClient(await getAccessTokenSilently());
        await apiClient.post(
            `{clientId}/users/remove`,
            {
                id: deleteUser.id,
                name: deleteUser.name,
                email: deleteUser.email
            }
        ) || {};
        fetchUsersData();
        setConfirmDeleteOpen(false);
    };

    const showDeleteConfirm = (user: any) => {
        setDeleteUser(user);
        setConfirmDeleteOpen(true);
    }
    const clearNewUser = () => {
        setModalOpen(false);
        setNewUser({
            "name": "",
            "email": "",
            "UserFathomClientRole": "viewer"
        })
    };

    const getInvites = () => {
        return invites.map((x: any) => (
            <Paper sx={{ p: 2, display: "flex", flexDirection: "column", marginBottom: "20px" }} key={x.id}>
                <Container>
                    <Grid container spacing={2}>
                        <Grid sm={7} item>
                            <Typography
                                sx={{
                                    marginTop: "10px",
                                }}
                                variant="h6"
                            >
                                {x.title}
                            </Typography>
                            <Typography variant="subtitle1">{x.userName}</Typography>
                            <Typography sx={{
                                marginBottom: "20px",
                            }} variant="subtitle2">{x.userEmail}</Typography>
                        </Grid>
                        <Grid sm={3} item>
                            <Typography
                                sx={{
                                    marginTop: "35px",
                                    color: "#4126A6",
                                }}
                            >
                                {x.clientRole}
                            </Typography>

                        </Grid>

                        <Grid sm={2} item>

                        <Typography
                                sx={{
                                    marginTop: "35px",
                                    color: "#4126A6",
                                }}
                            >
                                {x.accepted ? 'Accepted on ' + Moment(x.acceptedOnUtc).format("d MMM yyyy") : (x.declinedOnUtc > '0001-01-01' ? 'Declined on ' + Moment(x.declinedOnUtc).format("d MMM yyyy") : 'Pending')}
                            </Typography>

                        </Grid>
                        <br />
                        <br />
                        <br />
                        <br />
                    </Grid>
                </Container>
            </Paper>
        ));
    };

    const getUsers = () => {
        return users.map((x: any) => (
            <Paper sx={{ p: 2, display: "flex", flexDirection: "column", marginBottom: "20px" }} key={x.id}>
                <Container>
                    <Grid container spacing={2}>
                        <Grid sm={7} item>
                            <Typography
                                sx={{
                                    marginTop: "10px",
                                }}
                                variant="h6"
                            >
                                {x.title}
                            </Typography>
                            <Typography variant="subtitle1">{x.name}</Typography>
                            <Typography sx={{
                                marginBottom: "20px",
                            }} variant="subtitle2">{x.email}</Typography>
                        </Grid>
                        <Grid sm={3} item>
                            <Typography
                                sx={{
                                    marginTop: "35px",
                                    color: "#4126A6",
                                }}
                            >
                                {x.userFathomClientRole}
                            </Typography>

                        </Grid>

                        <Grid sm={2} item>
                            <Typography
                                sx={{
                                    marginTop: "30px",
                                    color: "red",
                                }}
                            >
                                <Button variant="text" onClick={() => showDeleteConfirm(x)}>
                                    <CloseIcon />
                                </Button>
                            </Typography>

                        </Grid>
                        <br />
                        <br />
                        <br />
                        <br />
                    </Grid>
                </Container>
            </Paper>
        ));
    };

    const getModal = () => {
        return (<Modal
            sx={style}
            open={modalOpen}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Container>
                <Typography id="modal-modal-title" variant="h6" component="h2">
                    Add new user
                </Typography>
                <hr />
                <InputLabel id="name-text-label">
                    Name
                </InputLabel>
                <TextField id="outlined-basic" fullWidth variant="outlined"
                    value={newUser.name}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        setNewUser({ ...newUser, name: event.target.value });
                    }} />
                <br />
                <br />
                <InputLabel id="email-text-label">
                    Email
                </InputLabel>
                <TextField id="outlined-basic" fullWidth variant="outlined"
                    value={newUser.email}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        setNewUser({ ...newUser, email: event.target.value });
                    }} />
                <br />
                <br />
                <InputLabel id="role-select-label">Role</InputLabel>
                <Select
                    sx={{ width: "100%" }}
                    labelId="role-select-label"
                    id="role-select"
                    value={newUser.UserFathomClientRole}
                    label="Role"
                    onChange={(mode: SelectChangeEvent) => {
                        setNewUser({ ...newUser, UserFathomClientRole: mode.target.value });
                    }}
                >
                    <MenuItem value={"Viewer"}>Viewer</MenuItem>
                    <MenuItem value={"Admin"}>Admin</MenuItem>
                </Select>
                <br />
                <br />
                <br />
                <br />
                <hr />
                <div style={{ display: "flex" }}>
                    <Button variant="contained" disabled={saveUserClicked} onClick={() => saveUser()}>
                        Save
                    </Button>

                    <Button style={{ marginLeft: "auto" }} variant="outlined" onClick={() => clearNewUser()}>
                        Close
                    </Button>
                </div>
            </Container>
        </Modal>)
    }

    return (
        <div>
            {getModal()}
            <ConfirmDialog
                title="Delete User"
                content="Are you sure you want to delete this user?"
                open={confirmDeleteOpen}
                setOpen={setConfirmDeleteOpen}
                confirmAction={removeUser} />
            <Container>
                <Toolbar />
                <Grid item xs={12}>
                    <br />
                    <Container>


                        <TabContext value={tabValue}>
                            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                <TabList onChange={handleChange} aria-label="Module info" variant="scrollable" scrollButtons="auto">
                                    <Tab key={0} label="users" wrapped value={'0'} />
                                    <Tab key={1} label="invites" wrapped value={'1'} />
                                </TabList>
                            </Box>
                            <TabPanel key={0} value={'0'}>
                                {loading && users.length == 0 &&
                                    <Paper sx={{ p: 2, display: "flex", flexDirection: "column", marginBottom: "20px" }} >
                                        <LinearProgress color="secondary" />
                                        <br />
                                        <br />
                                        <br />
                                    </Paper>
                                }
                                {users.length == 0 && !loading && (
                                    <Paper>
                                        {" "}
                                        <Container>
                                            <br />
                                            <Typography variant="h6">
                                                Add your first user
                                            </Typography>
                                            <br />
                                        </Container>
                                    </Paper>
                                )}
                                {getUsers()}

                            </TabPanel>
                            <TabPanel key={1} value={'1'}>
                            {loadingInvites && invites.length == 0 &&
                                    <Paper sx={{ p: 2, display: "flex", flexDirection: "column", marginBottom: "20px" }} >
                                        <LinearProgress color="secondary" />
                                        <br />
                                        <br />
                                        <br />
                                    </Paper>
                                }
                                {invites.length == 0 && !loadingInvites && (
                                    <Paper>
                                        {" "}
                                        <Container>
                                            <br />
                                            <Typography variant="h6">
                                                No invites yet
                                            </Typography>
                                            <br />
                                        </Container>
                                    </Paper>
                                )}
                                {getInvites()}

                            </TabPanel>

                        </TabContext>
                    </Container>
                    <br /><br />
                </Grid>
            </Container>
            <Paper
                sx={{
                    marginTop: "calc(10% + 60px)",
                    position: "fixed",
                    bottom: 0,
                    width: "100%",
                    height: "72px",
                }}
                component="footer"
                square
                variant="outlined"
            >
                <Container>
                    <br />
                    <Grid container spacing={2}>
                        <Grid
                            sm={7}
                            item
                            sx={{
                                marginTop: "5px",
                            }}
                        >
                            <Box
                                display={{ xs: "none", sm: "block" }}
                                style={{ height: "100vh" }}
                            >
                                <Grid container spacing={1}>
                                    <Grid
                                        sm={1}
                                        item
                                        sx={{
                                            marginTop: "0px",
                                            color: "#4126A6",
                                        }}
                                    ></Grid>
                                    <Grid sm={6} item>
                                        <Title></Title>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Grid>
                        <Grid sm={5} item>
                            <Button variant="contained" onClick={() => inviteUser()}>
                                Create
                            </Button>
                        </Grid>
                    </Grid>
                </Container>
            </Paper>
        </div>
    );
}