import { useAuth0 } from "@auth0/auth0-react";
import React from "react";
import Cookies from "universal-cookie";
export const CallbackPage = () => {
const env = process.env.REACT_APP_ENV;
const cookies = new Cookies();
const { user, error } = useAuth0();
if (error) {
    console.log(error)
    return (
        <div className="content-layout">
          <h1 id="page-title" className="content__title">
            Error
          </h1>
          <div className="content__body">
            <p id="page-description">
              <span>{error.message}</span>
            </p>
          </div>
        </div>
    );
  }

  if(user){
    const roles = user["fathom/client_roles"];
    if(roles != undefined){      

      var envRoles = Object.keys(roles).filter(function(role){ 
        return roles[role].Environment == env;
      });
      if(envRoles.length == 1 && cookies.get('x-fathom-clientId') == undefined){
        cookies.set('x-fathom-clientId', envRoles[0], {
          path: "/"
        } );
      }else{
        if(cookies.get('x-fathom-clientId') == undefined && location.pathname.indexOf("select") == -1 && location.pathname.indexOf("invite") == -1 ){
          window.location.assign("/select")
        }
      }
    }
  }

  window.location.assign("/programs")

  return (
    <div className="page-layout">
    </div>
  );
};