import * as React from 'react';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import DashboardIcon from '@mui/icons-material/Dashboard';
import PeopleIcon from '@mui/icons-material/People';
import BarChartIcon from '@mui/icons-material/BarChart';
import AssignmentIcon from '@mui/icons-material/Assignment';
import { Link } from 'react-router-dom';
import { ListItem } from '@mui/material';

const mainItems = [
  {
    text :"Dashboard",
    icon: DashboardIcon,
    to: "/"
  },
  {
    text :"Programs",
    icon: BarChartIcon,
    to: "/programs"
  },
  {
    text :"Users",
    icon: PeopleIcon,
    comp: Link,
    to: "/users"
  }
]

const secondaryItems = [
  {
    text :"Billing",
    icon: AssignmentIcon,
    to: "/"
  }
]


export const mainListItems = (open: boolean) => {
  return mainItems.map((item) => {
        return (<ListItem key={item.text} sx={{ display: 'block', marginBottom:"-20px", marginLeft:"0px" }}>
          <ListItemButton component={Link} to={item.to}
            sx={{
              minHeight: 48,
              justifyContent: open ? 'initial' : 'center',
              px: 2.5,
            }}>
            <ListItemIcon sx={{
              minWidth: 0,
              mr: open ? 3 : 'auto',
              justifyContent: 'center',
            }} >
              <item.icon className='routingIcon' />
            </ListItemIcon>
            <ListItemText sx={{ opacity: open ? 1 : 0 }} primary={item.text}  />
          </ListItemButton>
        </ListItem>)
      })};

export const secondaryListItems = (open: boolean) => {
  return secondaryItems.map((item) => {
    return (<ListItem key={item.text} sx={{ display: 'block', marginBottom:"-20px", marginLeft:"0px" }}>
      <ListItemButton component={Link} to={item.to} 
        sx={{
          minHeight: 48,
          justifyContent: open ? 'initial' : 'center',
          px: 2.5,
        }}>
        <ListItemIcon sx={{
          minWidth: 0,
          mr: open ? 3 : 'auto',
          justifyContent: 'center',
        }} >
          <item.icon className='routingIcon' />
        </ListItemIcon>
        <ListItemText sx={{ opacity: open ? 1 : 0 }} primary={item.text}  />
      </ListItemButton>
    </ListItem>)
  })};