import { Accordion, AccordionDetails, AccordionSummary, Container, Grid, Paper, Typography } from "@mui/material";
import { TopWin } from "../report";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import "./reportview.css";
import { GridCheckCircleIcon } from "@mui/x-data-grid";
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import React from "react";


export default function TopWinsTile({ report }: any) {

  const [expanded, setExpanded] = React.useState<number | null>(10);

  const handleChange =
    (panel: number) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : null);
    };

  return (
    <Paper>
      <Container>
        <br />
        <Typography variant="subtitle1"><QuestionAnswerIcon sx={{color:'#6327E6', marginRight: '10px'  }}/>Top {report.length} wins for the program</Typography>
        <Typography variant="subtitle2" sx={{color:'#6B6876'  }}>{report.length} summaries based on... </Typography>
        <br />
          {report.map((win:TopWin, i: number)  => {
            return (
                <Accordion sx={{
                  '&:before': {
                      display: 'none',
                  }
              }}
               className={expanded == i ? "winAccordianExpanded" : "winAccordian"} key={win.title} expanded={expanded == i} onChange={handleChange(i)}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1-content"
                  id="panel1-header"
                >
                  <GridCheckCircleIcon sx={{width: "16px", height: "16px", marginTop:"3px", marginRight:"5px", color: "#6327E6"}} /> {win.title}
                </AccordionSummary>
                <AccordionDetails>
                <Typography variant="subtitle2">{win.fullWinDetail}</Typography>
                </AccordionDetails>
              </Accordion>
            )
          })}

        <br />
      </Container>
    </Paper>
  )

}