import React, { useRef } from 'react';
import { Container, Grid, Paper, Typography } from '@mui/material';
import * as Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import PollIcon from '@mui/icons-material/Poll';
export default function ViewQuestionsReport({ report }: any) {

    const [currentQuestion, setCurrentQuestion] = React.useState(report.questionAnalysis.questionsSummary[0]);
    const [currentActiveQuestion, setActiveCurrentQuestion] = React.useState(0);
    const chartComponentRef = useRef<HighchartsReact.RefObject>(null);
    const getQuestions = () => {
        return report.questionAnalysis.questionsSummary.map((x: any, i: number) => (
            <Paper className={currentActiveQuestion == i ? 'activeQuestion' : ''} sx={{ p: 2, display: "flex", cursor: 'pointer', flexDirection: "column", marginBottom: "20px" }}
                key={i}
                onClick={() => {
                    setCurrentQuestion(x);
                    setActiveCurrentQuestion(i);
                }
                }>
                <Container>
                    <Grid container spacing={2}>
                        <Grid sm={12} item>
                            <Typography variant="subtitle2"><QuestionAnswerIcon sx={{ color: '#45C27E', width: '17px', height: '17px', marginRight: '5px', marginTop: "-1px" }} /> Q{i + 1}</Typography>
                            <Typography variant="subtitle1">{x.questionHeader}</Typography>
                        </Grid>
                    </Grid>
                </Container>
            </Paper>
        ));
    };




    return (
        <Container>
            <br />
            <br />
            <Paper id="reportTitle" className='reportTile'>
                <Typography variant="subtitle1"><PollIcon sx={{ color: '#6327E6', marginRight: '10px', marginTop: "-2px" }} />Summary</Typography>
                <hr />
                {report.questionAnalysis.overallSummary.split('.').filter((v:string) => v.trim()).map((content:string, i : number) => <p key={i}>{content.trim()}.</p>)}
                
            </Paper>
            <br /><br />
            <Grid container spacing={4}>
                <br />
                <Grid item xs={4}>
                    Questions
                    {getQuestions()}
                </Grid>
                <Grid item xs={8}>
                    Breakdown
                    {currentQuestion.multiResponses && currentQuestion.multiResponses?.map((multi: any) => {
                        let rate = 100;
                        let unres = 0;
                        if (multi.multiCount.hasOwnProperty('')) {
                            unres = multi.multiCount[""];
                            let res = report.participants;

                            rate = Math.round(100 - (unres / res * 100))
                        }

                        let options: Highcharts.Options = getMultiChartOptions(multi);
                        return (
                            <Paper className='reportTile' key={multi.title}>
                                <Typography sx={{opacity: "0.8", textTransform: 'none'}}>{rate}% response rate {unres > 0 && <span>({unres} participants didn&apos;t respond)</span>}</Typography>
                                <br />
                                <br />
                                <HighchartsReact
                                    highcharts={Highcharts}
                                    options={options}
                                    ref={chartComponentRef}
                                />
                            </Paper>
                        )
                    })}

                    {currentQuestion.multiResponses && currentQuestion.opinionResponses?.map((multi: any) => {
                        let options: Highcharts.Options = getOpinionChartOptions(multi);

                        return (
                            <Paper className='reportTile' key={multi.title}>
                                <HighchartsReact
                                    highcharts={Highcharts}
                                    options={options}
                                    ref={chartComponentRef}
                                />
                            </Paper>
                        )
                    })}

                    {currentQuestion.ratingResponses && currentQuestion.ratingResponses?.map((rating: any) => {

                        let options: Highcharts.Options = getRatingChartOptions(rating);

                        return (
                            <Paper sx={{ marginBottom: "25px", borderShadow: "0px" }} key={rating.title}>
                                <HighchartsReact
                                    highcharts={Highcharts}
                                    options={options}
                                    ref={chartComponentRef}
                                />
                            </Paper>
                        )
                    })}
                    {currentQuestion.headers.length > 1 &&
                        <Paper sx={{ p: 2, display: "flex", flexDirection: "column", marginBottom: "20px" }} >
                            <Typography variant="h6">{currentQuestion.questionHeader}</Typography> <hr />
                            <Typography variant="subtitle2">{currentQuestion.summary}</Typography><br />
                        </Paper>}
                    {currentQuestion.headers.map((header: any) => {
                        return (
                            <Paper sx={{ marginBottom: "25px", borderShadow: "10px" }} key={header.heading}>
                                <Container>
                                    <br />
                                    <Typography variant="h6">{header.heading}</Typography> <br />
                                    <Typography variant="subtitle1">{header.description}</Typography> <br />
                                    {header.quote.map((quote: any) => {
                                        return (
                                            <> <Typography variant="subtitle1"><i>&quot;{quote}&quot;</i></Typography> <br /></>)
                                    })}

                                </Container>
                            </Paper>
                        )
                    })}

                </Grid>
            </Grid>
        </Container>
    )
}

function getOpinionChartOptions(multi: any): Highcharts.Options {

    return {
        title: {
            text: multi.title,
        },
        yAxis: {
            reversedStacks: false
        },
        xAxis: {
            categories: [''],
            reversedStacks: false
        }, plotOptions: {
            series: {
                stacking: 'normal',
                dataLabels: {
                    enabled: true
                }
            }
        },
        series: GetOrderedOpinionKeys(multi.multiCount).map((key: string) => {
            return {
                'name': key,
                type: 'bar',
                data: [multi.multiCount[key]],
            };
        }),

    };
}

function GetOrderedOpinionKeys(multi: Record<string, number>) {

    const order = [
        "not confident at all",
        "a little confident",
        "somewhat confident",
        "quite confident",
        "very confident",
        "strongly disagree",
        "disagree",
        "neutral",
        "neither agree nor disagree",
        "agree",
        "strongly agree",
        "never",
        "rarely",
        "sometimes",
        "often",
        "always",
        "very dissatisfied",
        "dissatisfied",
        "neutral",
        "neither satisfied nor dissatisfied",
        "satisfied",
        "very satisfied"
    ]
    return Object.keys(multi).sort(function (a, b) {
        return order.indexOf(a.toLowerCase()) - order.indexOf(b.toLowerCase())
    });
}

function getMultiChartOptions(multi: any): Highcharts.Options {

    return {
        title: {
            text: multi.title,
        },
        yAxis: {
            reversedStacks: false
        },
        xAxis: {
            categories: [''],
            reversedStacks: false
        }, plotOptions: {
            series: {
                stacking: 'normal',
                dataLabels: {
                    enabled: true
                }
            }
        },
        series: GetOrderedKeys(multi.multiCount).map((key: string) => {
            return {
                'name': key,
                type: 'bar',
                data: [multi.multiCount[key]],
            };
        }),

    };
}


function GetOrderedKeys(multi: Record<string, number>) {
    return Object.keys(multi).filter(z => z !== "").sort(function (a, b) {
        return parseInt(a) - parseInt(b);
    });
}

function getRatingChartOptions(rating: any): Highcharts.Options {
    return {
        title: {
            text: rating.title,
        },
        yAxis: {
            reversedStacks: false
        },
        xAxis: {
            categories: [''],
            reversedStacks: false,
        }, plotOptions: {
            series: {
                dataLabels: {
                    enabled: true,
                }
            },

        },
        series: GetOrderedKeys(rating.ratingCount).map((key: string) => {
            return {
                'name': key,
                type: 'column',
                data: [rating.ratingCount[key]],

            };
        }),
    };
}
