import { Modal, Container, Typography, InputLabel, TextField, Select, SelectChangeEvent, MenuItem, Button, Grid, Paper, Box } from "@mui/material";
import { Survey } from "../types";
import { useState } from "react";

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '56%',
    transform: 'translate(-50%, -50%)',
    width: 1000,
    height: 900,
    bgcolor: 'white',
    boxShadow: 24,
    p: 4,
    "& > .MuiBackdrop-root": {
        bgcolor: 'white'
    }
};

type TypeMapperProps = {
    survey: Survey | undefined,
    modalOpen: boolean,
    updateQuestionType: (headerIndex: number, questionIndex: number, type:string) => void,
    setModalOpen: any,
    triggerAnalysis: () => void
};
const TypeMapper = ({ survey, modalOpen, updateQuestionType, setModalOpen, triggerAnalysis }: TypeMapperProps) => {
    const [isClicked, setIsClicked] = useState(false);
    const getQuestions = () => {
        return (
            <Grid container spacing={2} sx={{overflowY: 'scroll', height: 735}}>
                {survey?.structure?.map((qh, i) => {
                    return (<> <Grid item xs={12}><b>{qh.questionHeader}</b><hr/></Grid> 
                    {qh.questions.map((q, y) => {
                        return (<>
                            <Grid item xs={6}>{q.title}</Grid>
                            <Grid item xs={6}>
                                <Select
                                    sx={{ width: "100%" }}
                                    labelId="role-select-label"
                                    id="role-select"
                                    value={q.type}
                                    label="Role"
                                    onChange={(mode: SelectChangeEvent) => {
                                        updateQuestionType(i,y,mode.target.value)
                                    }}
                                >
                                    <MenuItem value={"multi"}>Multi-choice</MenuItem>
                                    <MenuItem value={"rating"}>Rating</MenuItem>
                                    <MenuItem value={"open"}>Open text</MenuItem>
                                    <MenuItem value={"opinion"}>Opinion scale</MenuItem>
                                </Select>
                            </Grid> 
                        </>
                    )
                    })}</>)
                })}
            </Grid>
        )
    }

    const getModal = () => {
        return (<Modal
            sx={style}
            open={modalOpen}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Container>
                <Typography id="modal-modal-title" variant="h6" component="h2">
                    Confirm mappings
                </Typography>
                <br />
                {getQuestions()}

                <Box sx={{
                    position: "fixed",
                    bottom: 20,
                    width: "85%",
                    height: "72px",
                }}>
                <hr />

                <div style={{ display: "flex", bottom: "0" }}>
                
                    <Button  variant="outlined" onClick={()=>setModalOpen(false)}>
                        Close
                    </Button>

                    <Button style={{ marginLeft: "auto" }} disabled = {isClicked} onClick={() => 
                    {
                        setIsClicked(true);
                        triggerAnalysis();
                        setIsClicked(false);
                    }} variant="contained">
                        Start Analysis
                    </Button>

                </div>
                </Box>
            </Container>
        </Modal>)
    }
    return (getModal())
}

export default TypeMapper;