import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import React from 'react';
import { Box, Container } from '@mui/material';
import { Module } from './report';
import ModuleDetailTab from './tiles/moduleDetail';


export default function ViewFullReport({ report }: any) {

    const [value, setValue] = React.useState('0');

    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        setValue(newValue);
    };


    return (
        <Container maxWidth="lg">
            <br />
            <br />
            <TabContext value={value}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <TabList onChange={handleChange} aria-label="Module info" variant="scrollable" scrollButtons="auto">
                        {report.modules.map((module:Module, i:number) => {
                            return (<Tab key={i} label={module.moduleName} wrapped value={i.toString()} />)
                        })}
                    </TabList>
                </Box>

                {report.modules.map((module:Module, i:number) => {
                    return (<TabPanel key={i} value={i.toString()}>
                        <ModuleDetailTab module={module}/>
                    </TabPanel>)
                })}

            </TabContext>
            </Container>
    )
}