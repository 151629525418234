
import axios, { AxiosError, AxiosInstance,  AxiosResponse, InternalAxiosRequestConfig } from "axios";
import { enqueueSnackbar } from "notistack";
import Cookies from "universal-cookie";

const cookies = new Cookies();

const api = axios.create({
    baseURL: process.env.REACT_APP_API_ENDPOINT,
  });

setupInterceptorsTo(api);

api.defaults.withCredentials = true;

function handleError(error:any){
  if (error.response) {
    // The request was made and the server responded with a status code
    // that falls out of the range of 2xx
    console.log(error.response.data);
    console.log(error.response.status);
    console.log(error.response.headers);

    enqueueSnackbar(error.message, {
      variant: "error",
      autoHideDuration: 5000,
      anchorOrigin: {
        vertical: 'top',
        horizontal: 'right'
      }
  });
  } else if (error.request) {
    // The request was made but no response was received
    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
    // http.ClientRequest in node.js
    console.log(error.request);

    enqueueSnackbar(error.message, {
      variant: "warning",
      autoHideDuration: 5000,
      anchorOrigin: {
        vertical: 'top',
        horizontal: 'right'
      }
  });
  } else {
    // Something happened in setting up the request that triggered an Error
    console.log('Error', error.message);
    enqueueSnackbar(error.message, {
      variant: "error",
      autoHideDuration: 5000,
      preventDuplicate: false,
      anchorOrigin: {
        vertical: 'top',
        horizontal: 'right'
      }
  });
  }
  console.log(error.config);
}

function setupInterceptorsTo(axiosInstance: AxiosInstance): AxiosInstance {
  axiosInstance.interceptors.request.use((res) => {return res},handleError);
  axiosInstance.interceptors.response.use((res) => {return res},handleError);
  return axiosInstance;
}

export default class FathomClient {
  constructor(token: string){
    api.defaults.headers.common['Authorization'] = "Bearer " + token;
  }

  private replaceClientId(endpoint:string){
    if (endpoint.search("{clientId}") > -1 ) { 
      var clientId = cookies.get("x-fathom-clientId");
      endpoint = endpoint.replace("{clientId}", clientId);
    }

    return endpoint;
  }

  get(endpoint: string)  {
    endpoint = this.replaceClientId(endpoint);
    return api.get(endpoint);
  }

  post(endpoint: string, data: any)  {
    endpoint = this.replaceClientId(endpoint);
    return api.post(endpoint, data);
  }

  put(endpoint: string, data: any)  {
    endpoint = this.replaceClientId(endpoint);
    return api.put(endpoint, data);
  }

  delete(endpoint: string,)  {
    endpoint = this.replaceClientId(endpoint);
    return api.delete(endpoint);
  }
}