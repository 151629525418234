import * as React from "react";
import { styled, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import MuiDrawer from "@mui/material/Drawer";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import Container from "@mui/material/Container";
import { mainListItems, secondaryListItems } from "./listItems";
import "./dashboard.css";
import theme from "../../app/theme";
import Routing from "../routing";
import { useAuth0 } from "@auth0/auth0-react";
import { CSSObject, Grid, Menu, MenuItem, Theme, Typography } from "@mui/material";
import Cookies from 'universal-cookie';
import { SnackbarProvider } from "notistack";
import { useLocation, useNavigate } from "react-router-dom";
import FathomClient from "api/fathomapi";
import { useEffect, useState } from "react";
import MiniDrawer from "./drawer";


const cookies = new Cookies();

const drawerWidth: number = 268;


interface Metadata {
  name: "",
  email: "",
  clientRoles: {
    [cId:string]:
      {
        role: "",
        name: ""
      }
  }
}

export default function Dashboard() {

  const { getAccessTokenSilently } = useAuth0();
  const { user, logout } = useAuth0();
  const [userClientDto, setUser] = useState({} as Metadata);
  const [clientId, setClientId] = useState("");
  const [loading, setLoading] = useState(true);
  const [userLoading, setUserLoading] = useState(true);
  const [showSidebar, setShowSidebar] = useState(true);
  const [clientCookie, setClientCookie] = useState(cookies.get("x-fathom-clientId"));
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const navigate = useNavigate();
  const env = process.env.REACT_APP_ENV;

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSwitch = () => {
    setAnchorEl(null)
    navigate("/select/")
  }
  const location = useLocation();

  if(user){
    const roles = user["fathom/client_roles"];
    if(roles != undefined){      

      var envRoles = Object.keys(roles).filter(function(role){ 
        return roles[role].Environment == env;
      });

      if(envRoles.length == 1 && cookies.get('x-fathom-clientId') == undefined){
        cookies.set('x-fathom-clientId', envRoles[0], {
          path: "/"
        } );
        window.location.assign("/programs")
      }else{
        if(cookies.get('x-fathom-clientId') == undefined && location.pathname.indexOf("select") == -1 && location.pathname.indexOf("invite") == -1 ){
          navigate("/select/");
        }
      }

      if(cookies.get('x-fathom-clientId') !== undefined && Object.keys(roles).indexOf(cookies.get('x-fathom-clientId')) < 0){
          cookies.remove('x-fathom-clientId');
      }
    }
  }else {
    getAccessTokenSilently({ cacheMode: 'off' });
  }

  useEffect(() => {

    const fetchData = async () => {
        const api = new FathomClient(await getAccessTokenSilently())
        const { data } = await api.get(`auth/profile`);
        setUser(data);
        setLoading(false);
    }

    setClientId(cookies.get('x-fathom-clientId'))    
    setUserLoading(false)

    if(clientCookie === undefined || clientCookie === ""){
      setShowSidebar(false)
    }
  
    fetchData()
      .catch(() => {
        logout({ logoutParams: { returnTo: window.location.origin } })
    });
  }, [getAccessTokenSilently, clientCookie])

  const getClientName = () => {
    if(user){
      const roles = user["fathom/client_roles"];
      if(roles != undefined && Object.keys(roles).length == 1){
        return( <Typography variant="h6" sx={{
          marginTop: "20px",
      }}>{!loading && userClientDto?.clientRoles[clientId]?.name}</Typography>)
      }else{
        return (
          <div>
              <Typography sx={{
          marginTop: "20px",
      }} onClick={handleClick} variant="h6">{userClientDto?.clientRoles[clientId]?.name}</Typography>
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                'aria-labelledby': 'basic-button',
              }}
            >
              <MenuItem onClick={() => handleSwitch() }>Switch client</MenuItem>
            </Menu>
          </div>
        );
      }
    }
  }



  return (
    <ThemeProvider theme={theme}>
      <SnackbarProvider />
      {!userLoading && 
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        {showSidebar &&
        <MiniDrawer loading={loading} getClientName={getClientName} user={user}></MiniDrawer>
}
        <Box
          component="main"
          sx={{
            backgroundColor: "#F3F3F4",
            flexGrow: 1,
            height: "100vh",
            overflow: "auto",
          }}
        >
            <Routing />
          
        </Box>
      </Box>
}
    </ThemeProvider>
  );
}
