import { Grid, Typography, Container } from "@mui/material";
import DepartmentTile from "./tiles/department";
import RegionTile from "./tiles/region";
import TopReccsTile from "./tiles/topreccs";
import TopWinsTile from "./tiles/topwins";

export default function ViewExecReport({ report }: any) {

    const preProgram = () => {
        return report.evaluationPoint.toLowerCase() == "preprogram";
    }

    return (
        <>
            <br />
            <Grid container spacing={4}>
                <Grid item xs={12}>
                    <Typography variant="subtitle1">Participants: {report.participants}</Typography>
                </Grid>
                {!preProgram() &&
                    <>
                        <br />
                        <br />
                        <br />
                        <Container>
                            <Grid container spacing={6}>
                                <Grid item xs={6}>
                                    <RegionTile report={report.regionCount} />
                                </Grid>
                                <Grid item xs={6}>
                                    <DepartmentTile report={report.departmentCount} />
                                </Grid>
                            </Grid>

                        </Container>

                        <Container>
                            <Grid container spacing={6}>
                                <Grid item xs={6}>
                                    <TopWinsTile report={report.topWins} />
                                </Grid>
                                <Grid item xs={6}>
                                    <TopReccsTile report={report.overallRecommendations} />
                                </Grid>
                            </Grid>
                        </Container>

                    </>}
            </Grid>
        </>);
}